import { useEffect, useMemo, useRef, useState } from "react";
import { Area } from "react-easy-crop";
import { Field, Form, FormSpy } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, FormControl, ImageAvatar, Loader } from "ui-2";
import {
  hasAllowedExt,
  hasAllowedSize,
  hasDoubleExtInFileName,
  hasMultiPeriodInFileName,
} from "utils";
import { AddPetRequest, PetData } from "../../types/pets";
import { useSetAddPet } from "../../api-calls/useSetAddPet";
import { useSetUpdatePet } from "../../api-calls/useSetUpdatePet";
import { PhotoCropper } from "../../components/Cropper";
import { ADD_EDIT_PET } from "../../constants/addEditPet";
import {
  ALLOWED_IMAGE_EXT,
  MESSAGES,
  SPECIAL_CHAR,
} from "../../constants/index";
import { ROUTES_PATH } from "../../constants/routes";
import { useMyPets } from "../../hooks/useMyPet";
import { AddIcon } from "../../icons/AddIcon";
import BreedSelection from "../BreedSelection";
import DateInput from "../DateInput";
import * as Modal from "../Modal";
import { useToastConfig } from "../toast";
interface FormValues {
  petImage?: File;
  petName?: string;
  petNickname?: string;
  petType?: string;
  petGender?: string;
  petBreed?: {
    petBreedId: number | null;
    petBreedName: string;
    petSizeId: number;
    petTypeId: number;
  };
  petSize?: number;
  petBirthday?: string;
  petBirthdate?: string;
  petAdoptionDate?: string;
}

const filterEmptyValues = (obj: Partial<PetData>): Partial<PetData> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) =>
      key === "petBreedId" ? true : value
    )
  ) as Partial<PetData>;
};

export const PetForm = () => {
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { refetchPetList, setIsViewRememberedPets } = useMyPets();

  const [formValues, setFormValues] = useState<AddPetRequest>({
    petData: [
      {
        petName: "",
        petNickName: "",
        petBreedId: 0,
        petSizeId: 0,
        petBirthdateTypeId: 0,
        petBirthday: "",
        petAdoptDate: "",
        petGenderId: 0,
        petTypeId: "",
      },
    ],
  });

  const { setAddPetData } = useSetAddPet(formValues);
  const { setUpdatePetData } = useSetUpdatePet(formValues);
  const [saveButtonClicked, setSaveButtonClicked] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPhotoCropperOpen, setIsPhotoCropperOpen] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [profilePic, setProfilePic] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [imageToUpload, setImageToUpload] = useState<{
    file: File;
    rendition: Area;
  }>();

  const initialFormValues = useMemo(() => {
    if (state) {
      const {
        petName,
        petNickName,
        petTypeId,
        petGenderId,
        petBreedId,
        petSizeId,
        petBirthdateTypeId,
        petBirthday,
        petAdoptDate,
        petImage,
      } = state;

      return {
        petName: petName ?? undefined,
        petNickname: petNickName ?? undefined,
        petType: petTypeId !== null ? String(petTypeId) : undefined,
        petGender: petGenderId !== null ? String(petGenderId) : undefined,
        petBreed:
          petBreedId !== null
            ? {
                petBreedId: petBreedId,
                petBreedName: state?.breedData?.petBreedName,
                petSizeId: petSizeId,
                petTypeId: petTypeId,
              }
            : undefined,
        petSize: String(petSizeId) ?? undefined,
        petBirthday:
          petBirthdateTypeId !== null ? String(petBirthdateTypeId) : undefined,
        petBirthdate: petBirthday ?? undefined,
        petAdoptionDate: petAdoptDate ?? undefined,
        petImage: petImage ?? undefined,
      };
    }

    return {};
  }, [state]);

  const onSave = async (values: FormValues) => {
    setSaveButtonClicked(true);
    const errors = validate(values);
    if (Object.keys(errors).length > 0) {
      showToast({
        title: "Error",
        type: "error",
        description: t("myPets.addEditPet.commonErrorWarning"),
      });
      return;
    }
  };

  const onSubmit = async (values: FormValues) => {
    const petId = state?.petId;
    const prepareFormValue: AddPetRequest = {
      petData: [
        filterEmptyValues({
          ...(petId && { petId }),
          petName: values.petName,
          petNickName: values.petNickname,
          petBreedId: values.petBreed?.petBreedId || null,
          petSizeId: values.petSize ? Number(values.petSize) : 0,
          petBirthdateTypeId: Number(values.petBirthday),
          petBirthday: values.petBirthdate,
          petAdoptDate: values.petAdoptionDate,
          petGenderId: values.petGender ? Number(values.petGender) : 0,
          petTypeId: values.petType,
          petImage: imageToUpload
            ? {
                file: imageToUpload.file,
                rendition: {
                  width: imageToUpload.rendition.width,
                  height: imageToUpload.rendition.height,
                  left: imageToUpload.rendition.x,
                  top: imageToUpload.rendition.y,
                },
              }
            : undefined,
        }) as PetData,
      ],
    };
    setFormValues(prepareFormValue);
    setIsLoading(true);
  };

  const handleInputClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const MAX_SIZE = process.env.REACT_APP_PROFILE_PIC_SIZE_IN_MB as string;

    if (files) {
      const selectedFile = files[0];
      const hasMultiPeriod = hasMultiPeriodInFileName(selectedFile.name);
      const isAllowedExt = hasAllowedExt(ALLOWED_IMAGE_EXT, selectedFile.type);
      const isAllowedSize = hasAllowedSize(+MAX_SIZE, selectedFile.size);
      const hasDoubleExt = hasDoubleExtInFileName(selectedFile.name);

      if (!hasMultiPeriod && isAllowedExt && isAllowedSize && !hasDoubleExt) {
        setImage(files[0]);
        setIsPhotoCropperOpen(true);
      } else {
        showToast({
          title: ADD_EDIT_PET.ADDED,
          description: !isAllowedExt
            ? `${t(MESSAGES.PROFILE_PIC_EXTENSION_ERROR)}`
            : hasMultiPeriod
            ? `${t(MESSAGES.PROFILE_PIC_MULTI_PERIOD_ERROR)}`
            : hasDoubleExt
            ? `${t(MESSAGES.PROFILE_PIC_EXTENSION_ERROR)}`
            : `${t(MESSAGES.PROFILE_PIC_SIZE_ERROR)}`,
          type: "error",
        });
      }
      e.target.value = "";
    }
  };

  const handleCroppedImageUpload = (
    croppedImage: string,
    croppedArea?: Area
  ) => {
    if (croppedImage && croppedArea) {
      const formData = new FormData();
      if (image) {
        setProfilePic(croppedImage);
        formData.append("image", image);
        formData.append("rendition", JSON.stringify(croppedArea));
        setImageToUpload({ file: image, rendition: croppedArea });
      }
    } else if (croppedImage) {
      setProfilePic(croppedImage);
    }
    setIsPhotoCropperOpen(false);
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    const specialCharRegex = SPECIAL_CHAR;
    if (!values.petName) {
      errors.petName = `${t("myPets.addEditPet.petsNameRequired")}`;
    } else if (specialCharRegex.test(values.petName)) {
      errors.petName = `${t("validations.noSpecialCharsNums")}`;
    }
    if (values.petNickname && specialCharRegex.test(values.petNickname)) {
      errors.petNickname = `${t("validations.noSpecialCharsNums")}`;
    }

    if (!values.petType) {
      errors.petType = `${t("validations.petTypeSelectionError")}`;
    }
    if (!values.petBirthday) {
      errors.petBirthday = `${t("validations.petBirthdateSelectionError")}`;
    }
    if (values.petBirthday && !values.petBirthdate) {
      errors.petBirthdate = `${t("myPets.addEditPet.validBirthdate")}`;
    }
    if (values.petBirthdate && values.petAdoptionDate) {
      if (
        !isAdoptionDateAfterBirthdate(
          values.petBirthdate,
          values.petAdoptionDate
        )
      ) {
        errors.petAdoptionDate = `${t(
          "myPets.addEditPet.petsAdoptionDateShouldBeGreater"
        )}`;
      }
    }
    return errors;
  };

  const isAdoptionDateAfterBirthdate = (
    birthdate: string,
    adoptionDate: string
  ): boolean => {
    const birthDateObj = new Date(birthdate);
    const adoptionDateObj = new Date(adoptionDate);
    return adoptionDateObj >= birthDateObj;
  };

  useEffect(() => {
    if (
      isLoading &&
      formValues.petData[0].petName !== "" &&
      formValues.petData[0].petBirthday !== "" &&
      formValues.petData[0].petTypeId !== ""
    ) {
      const submitForm = async () => {
        try {
          const apiResponse =
            state && state.petId
              ? await setUpdatePetData()
              : await setAddPetData();
          if (apiResponse?.data?.serviceStatus.code === 200) {
            refetchPetList();
            setIsViewRememberedPets(false);
            const petId =
              state && state.petId
                ? state.petId
                : apiResponse.data?.data[0]?.petId;
            navigate(`${ROUTES_PATH.MY_PETS}/?petId=${petId}`, {
              replace: true,
            });
            showToast({
              title: "Success",
              type: "success",
              description:
                state && state.petId
                  ? t("myPets.addEditPet.petUpdateMessage")
                  : t("myPets.addEditPet.petAddedMessage"),
            });
          } else {
            showToast({
              title: "Error",
              type: "error",
              description: t("myPets.addEditPet.petAddingError"),
            });
          }
        } catch (error) {
          showToast({
            title: "Error",
            type: "error",
            description: t("myPets.addEditPet.petAddingError"),
          });
        }
        setIsLoading(false);
      };

      submitForm();
    }
  }, [isLoading]);

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => validate(values)}
      initialValues={initialFormValues}
      render={({ handleSubmit, values, submitting, form, errors }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy subscription={{ values: true, active: true }}>
            {({ active, values, form }) => {
              const prevPetType = useRef(values.petType);
              useEffect(() => {
                if (
                  active === "petType" &&
                  values.petType !== prevPetType.current
                ) {
                  form.change("petBreed", undefined);
                  prevPetType.current = values.petType;
                }
              }, [values.petType]);

              useEffect(() => {
                if (values.petBirthday === "2") {
                  const dateParts = values?.petBirthdate?.split("-");
                  if (dateParts?.length === 3) {
                    const [year, month] = dateParts;
                    form.change("petBirthdate", `${year}-${month}-01`);
                  }
                }
                if (active === "petType") {
                  form.change("petBreed", undefined);
                }
              }, [values.petBirthday, values.petType, active, form]);

              return null;
            }}
          </FormSpy>
          <div className="h-fit px-5 pb-28 pt-8 lg:mx-[155px]">
            <div className="mt-8">
              <div className="label2 md:label1 mb-2">
                {t("myPets.addEditPet.petsProfilePic")}
              </div>
              <div className="flex items-center gap-2">
                <ImageAvatar
                  src={profilePic || initialFormValues?.petImage}
                  initials={values.petName?.charAt(0)}
                  imgProps={{
                    className: "!h-14 !w-14 md:!h-16 md:!w-16",
                  }}
                />
                <Button
                  prefixIcon={<AddIcon />}
                  variant="blueText"
                  onClick={handleInputClick}
                >
                  {profilePic || initialFormValues?.petImage
                    ? t("myPets.addEditPet.editImage")
                    : t("myPets.addEditPet.addImage")}
                </Button>
                <input
                  ref={inputRef}
                  id="pet-pic-upload"
                  aria-label="pet pic upload"
                  type="file"
                  accept={ALLOWED_IMAGE_EXT.join(",")}
                  onChange={handleFileChange}
                  name="petImage"
                  hidden
                />
                <PhotoCropper
                  open={isPhotoCropperOpen}
                  setOpen={setIsPhotoCropperOpen}
                  image={
                    image
                      ? URL.createObjectURL(image)
                      : initialFormValues.petImage || ""
                  }
                  aspect={1}
                  cropShape="round"
                  title={t("myPets.addEditPet.cropImage")}
                  setCroppedImage={handleCroppedImageUpload}
                />
              </div>
            </div>
            <div className="mt-8" data-testid="pet-name">
              <FormControl
                fieldProps={{
                  name: "petName",
                  component: "input",
                }}
                placeholder={`${t("myPets.addEditPet.petsNamePlaceholder")}`}
                label={t("myPets.addEditPet.petsName")}
                isRequired={true}
                errorShort={true}
              />
            </div>
            <div className="mt-8" data-testid="pet-nickname">
              <FormControl
                fieldProps={{
                  name: "petNickname",
                  component: "input",
                }}
                placeholder={`${t(
                  "myPets.addEditPet.petsNicknamePlaceholder"
                )}`}
                label={`${t("myPets.addEditPet.petsNickname")}`}
              />
            </div>
            <div className="mt-8" data-testid="pet-type">
              <div className="label2 sm:label1 mb-2">
                {`${t("myPets.addEditPet.petsType")}`}
                <span className="text-brand-color-library-red-800"> *</span>
              </div>
              <div className="flex flex-col gap-6 lg:flex-row">
                <FormControl
                  data-testid="pet-type-dog"
                  key={`dog_type`}
                  fieldProps={{
                    name: "petType",
                    component: "input",
                    value: "1",
                  }}
                  type="radio"
                  label={`${t("myPets.addEditPet.Dog")}`}
                  variant="default"
                />
                <FormControl
                  data-testid="pet-type-cat"
                  key={`cat_type`}
                  fieldProps={{
                    name: "petType",
                    component: "input",
                    value: "2",
                  }}
                  type="radio"
                  label={`${t("myPets.addEditPet.Cat")}`}
                  variant="default"
                />
              </div>
              {saveButtonClicked && errors?.petType && (
                <span className="text-sm text-brand-error">
                  {errors.petType}
                </span>
              )}
            </div>
            <div className="mt-8" data-testid="pet-gender">
              <div className="label2 sm:label1 mb-2">
                {`${t("myPets.addEditPet.petsGender")}`}
              </div>
              <div className="flex flex-col gap-6 lg:flex-row">
                <FormControl
                  key={`male_gender`}
                  fieldProps={{
                    name: "petGender",
                    component: "input",
                    value: "1",
                  }}
                  type="radio"
                  label={`${t("myPets.addEditPet.Male")}`}
                  variant="default"
                  data-testid="male"
                />
                <FormControl
                  key={`female_gender`}
                  fieldProps={{
                    name: "petGender",
                    component: "input",
                    value: "2",
                  }}
                  type="radio"
                  label={`${t("myPets.addEditPet.Female")}`}
                  variant="default"
                  data-testid="female"
                />
              </div>
            </div>
            <div className="mt-8" data-testid="search-breed">
              <div className="label2 sm:label1 mb-2">{`${t(
                "myPets.addEditPet.breed"
              )}`}</div>
              <BreedSelection
                setPetSize={(value) => form.mutators.setPetSize(value)}
                petTypeId={Number(values.petType)}
                initialBreed={initialFormValues?.petBreed}
              />
            </div>
            <div className="mt-8" data-testid="pet-size">
              <div className="label2 sm:label1 mb-2">{`${t(
                "myPets.addEditPet.petsSize"
              )}`}</div>
              <div className="flex flex-col gap-6 lg:flex-row">
                <FormControl
                  key="pets_small"
                  fieldProps={{
                    name: "petSize",
                    component: "input",
                    value: "1",
                  }}
                  type="radio"
                  label={
                    values.petType === "1"
                      ? t(ADD_EDIT_PET.SMALL_WEIGHT_RANGE_DOG)
                      : t(ADD_EDIT_PET.SMALL_WEIGHT_RANGE_CAT)
                  }
                  variant="default"
                  data-testid="small"
                />
                <FormControl
                  key="pets_medium"
                  fieldProps={{
                    name: "petSize",
                    component: "input",
                    value: "2",
                  }}
                  type="radio"
                  label={
                    values.petType === "1"
                      ? t(ADD_EDIT_PET.MEDIUM_WEIGHT_RANGE_DOG)
                      : t(ADD_EDIT_PET.MEDIUM_WEIGHT_RANGE_CAT)
                  }
                  variant="default"
                  data-testid="medium"
                />
                <FormControl
                  key="pets_large"
                  fieldProps={{
                    name: "petSize",
                    component: "input",
                    value: "3",
                  }}
                  type="radio"
                  label={
                    values.petType === "1"
                      ? t(ADD_EDIT_PET.LARGE_WEIGHT_RANGE_DOG)
                      : t(ADD_EDIT_PET.LARGE_WEIGHT_RANGE_CAT)
                  }
                  variant="default"
                  data-testid="large"
                />
              </div>
            </div>
            <div className="mt-8" data-testid="pet-birthday">
              <div className="label2 sm:label1 mb-2">
                {`${t("myPets.addEditPet.petsBirthday")}`}
                <span className="text-brand-color-library-red-800"> *</span>
              </div>
              <div className="flex flex-col gap-2">
                <FormControl
                  key={`birthday_type_actual`}
                  fieldProps={{
                    name: "petBirthday",
                    component: "input",
                    value: "1",
                  }}
                  type="radio"
                  label={`${t("myPets.addEditPet.actualDate")}`}
                  variant="block"
                  data-testid="pet-actual-birthday"
                >
                  {values?.petBirthday === "1" && (
                    <Field name="petBirthdate">
                      {({ input, meta }) => (
                        <div>
                          <span
                            className="mt-4 -mb-3"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <DateInput
                              showCalendar
                              {...input}
                              data-testid="pet-birthday-input"
                              value={values.petBirthdate}
                              errorHint={t("myPets.addEditPet.validBirthdate")}
                              max={new Date()}
                            />
                          </span>
                          {saveButtonClicked && !values?.petBirthdate && (
                            <span className="text-sm text-brand-error">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                  )}
                </FormControl>
                <FormControl
                  key={`birthday_type_approximate`}
                  fieldProps={{
                    name: "petBirthday",
                    component: "input",
                    value: "2",
                  }}
                  type="radio"
                  label={`${t("myPets.addEditPet.approximateDate")}`}
                  variant="block"
                  data-testid="pet-approximate-birthday"
                >
                  {values?.petBirthday === "2" && (
                    <Field name="petBirthdate">
                      {({ input, meta }) => (
                        <span
                          className="mt-4 -mb-3"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <DateInput
                            showCalendar
                            monthOnly
                            {...input}
                            data-testid="pet-approximate-input"
                            value={values.petBirthdate}
                            errorHint={t("myPets.addEditPet.validBirthdate")}
                            max={new Date()}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-sm text-brand-error">
                              {meta.error}
                            </span>
                          )}
                        </span>
                      )}
                    </Field>
                  )}
                </FormControl>
                {saveButtonClicked && errors?.petBirthday && (
                  <span className="text-sm text-brand-error">
                    {errors?.petBirthday}
                  </span>
                )}
              </div>
            </div>
            <div className="mt-8" data-testid="pet-adoption-date">
              <div className="label2 sm:label1 mb-2">
                {`${t("myPets.addEditPet.petsAdoptionDate")}`}
              </div>
              <Field name="petAdoptionDate">
                {({ input, meta }) => (
                  <span>
                    <DateInput
                      invalid={meta?.error}
                      {...input}
                      data-testid="adoption-date"
                      value={values.petAdoptionDate}
                      errorHint={t("myPets.addEditPet.validAdoptionDate")}
                    />
                    {meta?.error && (
                      <span className="text-sm text-brand-error">
                        {meta.error}
                      </span>
                    )}
                  </span>
                )}
              </Field>
            </div>
          </div>
          <FormSpy subscription={{ pristine: true }}>
            {({ pristine }) => {
              const isProcessing = submitting || isLoading;

              return (
                <Modal.Footer>
                  <Button
                    type="submit"
                    disabled={
                      (Object.keys(initialFormValues).length &&
                        !imageToUpload &&
                        pristine) ||
                      isProcessing
                    }
                    classes="lg:label1 w-[207px] disabled:opacity-100"
                    data-testid="save-button"
                    onClick={() => onSave(values)}
                  >
                    {isProcessing && (
                      <Loader
                        size="small"
                        svgClassname="text-white fill-brand-primary"
                      />
                    )}
                    {t("myPets.addEditPet.save")}
                  </Button>
                </Modal.Footer>
              );
            }}
          </FormSpy>
        </form>
      )}
    />
  );
};
