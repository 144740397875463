import { ROUTES } from "../../constants/routes";
import { fullUrl } from "../../lib/common/gtm";

export const GTM_ACTIONS = {
  LOGIN_SUCCESS: {
    user_type: "vet",
    method: "Gigya",
    event: "login",
    action: "login success",
  },
  ARTICLES: {
    user_type: "",
    method: "",
    event: "article",
    action: "related_article_click",
    section_title: "Related Articles",
    article_title: "",
    click_text: "",
    click_url: "",
  },
  EDITPIC: {
    user_type: "",
    method: "",
    event: "CTA",
    action: "click",
    click_text: "profile pic upload",
  },
  SIDENAV: {
    user_type: "",
    method: "",
    event: "navigation",
    nav_group: "toolbar",
    action: "click",
    click_text: "",
    click_url: "",
  },
  PETTILE: {
    user_type: "",
    method: "",
    event: "CTA",
    action: "click",
    click_text: "",
    click_text2: "",
    click_url: "",
  },
  ADDAPETTILE: {
    user_type: "",
    method: "",
    event: "CTA",
    action: "click",
    click_text: "",
    click_url: "",
  },
  VIEW_TAB: {
    event: "CTA",
    action: "click",
  },
  ADD_A_MILESTONE_DATE: {
    event: "CTA",
    action: "click",
    click_text: "add a date",
  },
  EDIT_A_MILESTONE_DATE: {
    event: "CTA",
    action: "click",
    click_text: "edit a date",
  },
  CLICK_A_COMPLETED_MILESTONE: {
    event: "CTA",
    action: "click",
    click_text: "click complete milestone",
  },
  LEFT_SIDE_NAV_MY_PETS: {
    event: "navigation",
    nav_group: "toolbar",
    action: "click",
    click_text: "left side vav my pets",
  },
  LEFT_SIDE_NAV_ADD_A_PET: {
    event: "navigation",
    nav_group: "toolbar",
    action: "click",
    click_text: "left side nav add a pet",
  },
  LEFT_SIDE_NAV_ACCOUNT_HOME: {
    event: "navigation",
    nav_group: "mainnav",
    category: "Account Home",
    click_url: fullUrl + "/" + ROUTES.ACCOUNT_HOME,
    click_text: "account home",
  },
  ACCOUNT_HOME_MY_PETS: {
    event: "navigation",
    nav_group: "subnav",
    action: "click",
    click_text: "account home my pets",
  },
  ACCOUNT_HOME_ADD_A_PET: {
    event: "CTA",
    action: "click",
    click_text: "account home add a pet",
  },
  ACCOUNT_HOME_GET_REBATE: {
    event: "CTA",
    action: "rebate_click",
    click_text: "get rebate",
  },
  ACCOUNT_HOME_REBATE_DISMISS: {
    event: "CTA",
    action: "rebate_click",
    click_text: "dismiss",
  },
  ACCOUNT_HOME_REBATE_CLOSE: {
    event: "CTA",
    action: "rebate_click",
    click_text: "close button",
  },
  ACCOUNT_HOME_REBATE_CLOSE_CHEKED: {
    event: "CTA",
    action: "rebate_click",
    click_text: "don't show this again",
  },
  MY_PETS_NO_PETS_ADD_A_PET: {
    event: "CTA",
    action: "click",
    click_text: "my pets carousel header add a pet",
  },
  MY_PETS_CAROUSEL_ADD_A_PET: {
    event: "CTA",
    action: "click",
    click_text: "my pets carousel header add a pet",
  },
  MY_PETS_PROFILE_EDIT_A_PET: {
    event: "navigation",
    nav_group: "subnav",
    action: "click",
    click_text: "my pets profile edit a pet",
  },
  MY_PETS_PROFILE_CLICK_REMEMBERED_PETS: {
    event: "CTA",
    action: "click",
    click_text: "my pets profile click remembered pets",
  },
  MY_PETS_PROFILE_KEBAB_HIDE_A_PET: {
    event: "CTA",
    action: "click",
    click_text: "my pets profile hide a pet",
  },
  MY_PETS_PROFILE_KEBAB_DELETE_A_PET: {
    event: "CTA",
    action: "click",
    click_text: "my pets profile delete a pet",
  },
  MY_PETS_DIET_SELECT_FOOD_CLICK: {
    event: "CTA",
    action: "diet_click",
    click_text: "my pets diet click select food",
  },
  MY_PETS_DIET_SELECT_HILLS_PRODUCT: {
    event: "CTA",
    action: "select food",
    click_text: "my pets diet click hill's product and open food select",
  },
  MY_PETS_DIET_SELECT_OTHER_BRAND: {
    event: "CTA",
    action: "select_food",
    click_text: "",
  },
  MY_PETS_DIET_SELECT_HOME_MADE: {
    event: "CTA",
    action: "select_food",
    click_text: "",
  },
  MY_PETS_DIET_SELECT_SAVE: {
    event: "CTA",
    action: "diet_click",
    click_text: "my pets diet click save food type modal",
  },
  MY_PETS_DIET_FOOD_SELECT_SAVE: {
    event: "CTA",
    action: "diet_click",
    click_text: "my pets diet food select save",
  },
  MY_PETS_DIET_CLICK_FEEDING_GUIDE: {
    event: "accordion",
    action: "open",
    accordion_name: "my pets diet click feeding guide",
  },
  MY_PETS_DIET_CLICK_FOOD_QUIZ: {
    event: "quiz",
    action: "diet_click",
    click_text: "my pets diet click food quiz",
  },
  ACCOUNT_SETTINGS: {
    event: "navigation",
    nav_group: "subnav",
    action: "click",
    click_text: "account settings",
  },
  ACCOUNT_SETTINGS_LOGOUT: {
    event: "login",
    action: "log off",
    user_type: "",
    method: "Gigya",
    click_text: "log off",
    login_step: "in-app-log-off",
  },
  QOTW_OPEN: {
    event: "CTA",
    action: "question_week",
    click_text: "answer now",
  },
  QOTW_SELECT_OPTION: {
    event: "CTA",
    action: "click",
  },
  QOTW_CLICK_KNOW_MORE: {
    event: "CTA",
    action: "click",
    click_text: "want to know more?",
  },
  QOTW_SUBMIT: {
    event: "CTA",
    action: "question_week",
    click_text: "submit",
  },
  QOTW_DONE: {
    event: "CTA",
    action: "click",
    click_text: "done",
  },
  SHARE_PET_PROFILE: {
    event: "CTA",
    action: "my_pets_profile",
    click_text: "share",
  },
};

export default GTM_ACTIONS;
